var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("e-sidebar", {
        attrs: {
          title: _vm.edit ? _vm.$t("Editar Marca") : _vm.$t("Nova Marca"),
          show: _vm.showSidebar,
          fetching: _vm.fetching,
          saving: _vm.saving,
          width: "500px",
        },
        on: { save: _vm.saveBrand, hidden: _vm.hideSidebar },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  [
                    _c(
                      "FormulateForm",
                      { ref: "brandForm", attrs: { name: "form" } },
                      [
                        _c("FormulateInput", {
                          staticClass: "required input-uppercase",
                          attrs: {
                            id: "brand-name",
                            type: "text",
                            label: _vm.$t("Nome"),
                            validation: "required",
                          },
                          model: {
                            value: _vm.localBrand.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.localBrand, "name", $$v)
                            },
                            expression: "localBrand.name",
                          },
                        }),
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "brand-api-id",
                            type: "number",
                            label: _vm.$t("Id API"),
                            instruction: _vm.$t(
                              "Id utilizado nas integrações com APIs externas"
                            ),
                            validation: "required",
                          },
                          model: {
                            value: _vm.localBrand.apiId,
                            callback: function ($$v) {
                              _vm.$set(_vm.localBrand, "apiId", $$v)
                            },
                            expression: "localBrand.apiId",
                          },
                        }),
                        _c("FormulateInput", {
                          attrs: {
                            id: "brand-manufacturer-id",
                            type: "vue-select",
                            label: _vm.$t("Fabricante"),
                            options: _vm.getComboManufactures,
                          },
                          model: {
                            value: _vm.localBrand.manufacturerId,
                            callback: function ($$v) {
                              _vm.$set(_vm.localBrand, "manufacturerId", $$v)
                            },
                            expression: "localBrand.manufacturerId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("FormulateInput", {
                      attrs: {
                        id: "brand-image-local",
                        type: "uploader",
                        deletable: "true",
                        "max-files": "1",
                        meta: false,
                        label: _vm.$t("Imagem"),
                      },
                      model: {
                        value: _vm.imageLocal,
                        callback: function ($$v) {
                          _vm.imageLocal = $$v
                        },
                        expression: "imageLocal",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }